.react-calendar__navigation {
  align-items: center;
}
.react-calendar__navigation__label {
  margin-top: 0.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  flex-grow: 2;
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next-button,
.react-calendar__navigation__next2-button {
  font-size: 2rem;
  flex-grow: 1;
}

.react-calendar__month-view__weekdays__weekday {
  font-weight: bold;
}

.react-calendar__viewContainer {
  margin-top: 1rem;
}
.react-calendar__month-view__days__day {
  position: relative;
  width: 100%;
  height: 13vw;
}

@media (min-width: 768px) {
  .react-calendar__month-view__days__day {
    height: 5vw;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #bdbdbd;
}

.react-calendar__tile--now {
  color: #2f80ed;
  font-weight: bold;
}

.react-calendar__tile--active {
  border-radius: 100%;
  background-color: #ee853a;
  color: white;
}
.react-calendar__tile--active.react-calendar__tile--now {
  color: #2f80ed;
}
