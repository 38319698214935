.sv-input.sv-tagbox:not(.sv-tagbox--empty):not(.sv-input--disabled) {
  height: auto;
  padding: 0.5em;
  padding-inline-end: 2em; // padding-right
}

.sv-tagbox_clean-button {
  height: 1.5em;
  padding: 0.5em;
  margin: auto 0;
}

.sv-tagbox__value.sv-dropdown__value {
  position: relative;
  gap: 0.25em;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-inline: unset;
  margin-inline: unset;
  margin-block: unset;
}

.sv-tagbox__item {
  position: relative;
  display: flex;
  color: var(--text-color, $text-color);
  height: 1.5em;
  // padding: 0.25em 0.4em 0.25em 0.87em;
  padding-block: 0.25em;
  padding-inline-end: 0.4em;
  padding-inline-start: 0.87em;
  border: solid 0.1875em #9f9f9f;
  border-radius: 2px;
  min-width: 2.3125em;
}

.sv-tagbox__item:hover {
  background-color: var(--main-hover-color, $main-hover-color);
  color: var(--body-background-color, $body-background-color);

  .sv-tagbox__item_clean-button-svg use {
    fill: var(--body-background-color, $body-background-color);
  }
}

.sv-tagbox__item-text {
  color: inherit;
  font-size: 1em;
}

.sv-tagbox__item_clean-button-svg {
  margin: 0.3125em;
  width: 1em;
  height: 1em;
}
.sv-tagbox__item_clean-button-svg use {
  fill: var(--text-color, $text-color);
}

.sv-tagbox__filter-string-input {
  width: auto;
  display: flex;
  flex-grow: 1;
  position: initial;
}
.sv-tagbox__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: auto;
  height: 100%;
  text-align: left;
  cursor: text;
  pointer-events: none;
  color: var(--main-hover-color, $main-hover-color);
}

.sv-tagbox {
  border-bottom: 0.06em solid var(--text-border-color, $text-border-color);
}
.sv-tagbox:focus {
  border-color: var(--text-border-color, $text-border-color);
}
.sv-tagbox--error {
  border-color: var(--error-color, $error-color);
  color: var(--error-color, $error-color);
}
.sv-tagbox--error::placeholder {
  color: var(--error-color, $error-color);
}
.sv-tagbox--error::-ms-input-placeholder {
  color: var(--error-color, $error-color);
}
