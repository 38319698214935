.scroll-box {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-box::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.card_shadow {
  border: 1px solid #e5e8eb;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.04), 0px 4px 30px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
}

.feedback_space a {
  color: #2f80ed;
}
.feedback_space a:hover {
  text-decoration: underline;
}

.svda-title-editor-placeholder {
  color: white;
}

.svda-title-editor-placeholder[aria-label='title']::after {
  content: '제목을 입력해주세요.';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #404040;
}

.svda-title-editor-placeholder[aria-label='description']::after {
  content: '설명을 입력해주세요.';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #404040;
}

.not-draggable {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}